import React from 'react';
import './App.css';
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import ImageLogo from "./house_straight_logo.png";

function App() {

  const url = process.env.REACT_APP_MAILCHIMP_SUBSCRIBE_URL;
  // The url looks like the url below:
  // https://aaaaaaaaa.us20.list-manage.com/subscribe/post?u=xxxxxxxxxxxxxxxxxx&amp;id=yyyyyyyyyy
  const {
      loading,
      error,
      success,
      message,
      handleSubmit
    } = useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
  });

  return (
    <div className="App">
      <header className="App-header">
      <p className="Header-text"><img src={ImageLogo} alt="house straight logo" className="house-straight-logo"/> HouseStraight.com &gt; Signup to receive our newsletter.</p>
        <div id="mc_embed_signup">
        <form
          onSubmit={event => {
            event.preventDefault();
            handleSubmit(fields);
          }}
        >
          <input
            id="EMAIL"
            autoFocus
            type="email"
            value={fields.EMAIL}
            onChange={handleFieldChange}
          />
          <button>submit</button>
        </form>
        {loading && "submitting"}
        {error && message}
        {success && message}
      </div>
      </header>
    </div>
  );
}

export default App;
